body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Tabs {
 width: 80%;
 height: auto;
 margin: 1rem auto 1rem;
 color: #000000;
 display: flex;
 align-items: center;
 justify-content: center;
 overflow: hidden;
 @media (max-width: 769px) {
  padding: 1rem 0;
 }
}

ul.nav {
  width: 60%;
   border: 1px solid #39A2DB;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0px;
  @media (max-width: 768px) {
    width: 90%;
  }
}
ul.nav li {
  width: 50%;
  padding: 1rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
}
ul.nav li:nth-child(2) {
  border-radius: 0;
}
ul.nav li:hover {
  background: rgba(50, 224, 196, 0.15);
}
ul.nav li.active {
  background: #39A2DB;
}

.NFT p,
.SecondTab p {
  font-size: 2rem;
  text-align: center;
}

.blueRow {
  background-color: rgba(49, 101, 212, 0.08);
  border: 1px solid #3165d4;
  padding: 14px;
  border-radius: 7px;
  align-items: center;
  margin-bottom: 5px;
}

.blueRowTitle {
  padding: 0 10px;
  align-items: flex-end;
}

.greater, .equal, .less {
  font-weight: bold;
}

.greater {
  color: #229904; 
  
}

.greater svg {
    height: 10px;
    color: #229904; 
}

.equal {
  color:#939393;
}
.equal svg {
  display: none;
}

.less {
  color: #ea1818;
}

.less svg{
  height: 10px;
  color: #ea1818;
  transform: rotate(180deg);
}